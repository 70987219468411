<template>
  <div>
    <b-col cols="12">
      <h2>Вопрос-Ответ</h2>
    </b-col>
    <update ref="updateModal"/>
    <b-overlay :show="loading">
      <b-col cols="12" class="d-flex">
        <b-card class="w-100 mb-4">
          <b-table :items="items" :fields="fields" :small="true">
            <template #cell(actions)="data">
              <div class="text-left">
                <b-icon class="action-icon" icon="pencil-fill" variant="primary" font-scale="1.2" @click="update(data.item)" title="Редактировать"></b-icon>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-overlay>
  </div>
</template>

<script>
import {mapState} from "vuex"
import Update from './Faq/Update'
import moment from "moment";

export default {
  name: "Faq",
  data() {
    return {
      errors: {},
      fields: [
        {
          key: 'id',
          label: 'ID'
        },
        {
          key: 'date',
          label: 'Дата',
          formatter:  value => moment(value).format('DD.MM.YYYY')
        },
        {
          key: 'name',
          label: 'Имя'
        },
        {
          key: 'question',
          label: 'Вопрос'
        },

        {
          key: 'isActive',
          label: 'Опубликовано',
          formatter: (value) => value ? 'Да' : 'Нет'
        },
        {
          key: 'actions',
          label: 'Действия'
        }
      ]
    }
  },
  mounted() {
    this.$store.dispatch('Faq/load', { page: 1 })
  },
  computed: {
    ...mapState({
      loading: state => state.Faq.loading,
      items: state => state.Faq.items,
      page: state => state.Faq.page,
      maxPages: state => state.Faq.maxPages,
    })
  },
  methods: {
    linkGen(pageNum) {
      return pageNum === 1 ? '?' : `?page=${pageNum}`
    },
    load(event, page) {
      event.preventDefault()
      this.$store.dispatch('Faq/load', { page })
    },
    update(form) {
      this.$refs.updateModal.show(form)
    }
  },
  components: {
    Update
  }
}
</script>

<style scoped>
.action-icon {
  margin: 4px;
  cursor: pointer;
}
</style>