<template>
  <b-form>
    <b-row>
      <b-col md="12">
        <p><b>Имя: </b>{{form.name}}</p>
        <p><b>Телефон: </b>{{form.phone}}</p>
        <p><b>Вопрос: </b>{{form.question}}</p>
        <b-form-group label="Ответ">
          <b-form-textarea v-model="form.answer" placeholder="Ответ" :state="formErrors.answer"></b-form-textarea>
        </b-form-group>
        <b-form-checkbox v-model="form.isActive">
          Опубликовать
        </b-form-checkbox>
      </b-col>
    </b-row>
    <div class="text-right mt-4">
      <b-button :variant="btnVariant" @click="onSave">{{ btnTitle }}</b-button>
    </div>
  </b-form>
</template>

<script>
import validate from "@/utils/formValidator"
import FAQ from "@/forms/FAQ"

const FAQ_INIT_FORM = () => ({
  name: '',
  phone: '',
  question: '',
  answer: '',
  isActive: false
})

export default {
  name: "Form",
  props: {
    init: { required: false, default: () => FAQ_INIT_FORM() },
    btnVariant: { required: true },
    btnTitle: { required: true }
  },
  data() {
    return {
      form: this.init,
      errors: {}
    }
  },
  watch: {
    form: {
      handler: function () {
        this.validateForm();
      },
      deep: true
    }
  },
  computed: {
    formErrors: function () {
      return Object.fromEntries(Object.entries(this.errors).map((item) => [item[0], !item[1]]))
    }
  },
  methods: {
    fillForm(form) {
        Object.assign(this.form, form)
    },
    resetForm() {
      Object.assign(this.form, FAQ_INIT_FORM())
      this.errors = {}
    },
    validateForm() {
      this.errors = validate(this.form, FAQ)
      return Object.keys(this.errors).length === 0
    },
    onSave() {
      if (!this.validateForm())
        return

      this.$emit('onSave', this.form);
    }
  }
}
</script>

<style scoped>
</style>